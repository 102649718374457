import React from 'react'
import './about.css'
import ME from '../../assets/about-me-1.jpg'
import { BsAward } from 'react-icons/bs'
import { FiUsers } from 'react-icons/fi'
import { AiOutlineFolderOpen } from 'react-icons/ai'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        
        <div className="about__content">
          <div className="about__cards">
            <article className='about_card'>
              <BsAward className='about__icon' />
              <h5>Experience</h5>
              <small>2+ Years Working</small>
            </article>

            <article className='about_card'>
              <FiUsers className='about__icon' />
              <h5>Clients</h5>
              <small>150+</small>
            </article>

            <article className='about_card'>
              <AiOutlineFolderOpen className='about__icon' />
              <h5>Projects</h5>
              <small>50+ Completed</small>
            </article>
          </div>

          <p>
          An experienced, enthusiastic and hard working web developer capable of using multiple standard programming
          language, independent and able to handle multiple task.
          </p>
          <a href='#contact' className='btn btn-primary'>Contact</a>

        </div>

        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt='About Image' />
          </div>
        </div>
      </div>

    </section>
  )
}

export default About