import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/port1.png'
import IMG2 from '../../assets/port2.png'
import IMG3 from '../../assets/port3.png'
import IMG4 from '../../assets/port4.png'
import IMG5 from '../../assets/port5.png'
import IMG6 from '../../assets/port6.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Evalveia Software Services',
    // github: 'https://github.com',
    demo: 'https://evlaveiasoftwareservices.com/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Dfecalled Restaurant',
    // github: 'https://github.com',
    demo: 'https://software.dfecalled.com/'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Buddha Courier',
    // github: 'https://github.com',
    demo: 'https://buddhacouriers.com.np/app/login'
  },
  {
    id: 4,
    image: IMG4,
    title: 'LADIMS',
    // github: 'https://github.com',
    demo: ''
  },
  {
    id: 5,
    image: IMG5,
    title: 'Soezze',
    // github: 'https://github.com',
    demo: 'http://2ezze.com/'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Byappar',
    // github: 'https://github.com',
    demo: 'http://inventory.byappar.com/'
  },
]
const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Works</h5>
      <h2>My Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({ id, image, title, github, demo }) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  {/* <a href={github} target='_blank' className='btn'>Github</a> */}
                  <a href={demo} target='_blank' className='btn btn-primary'>Live Demo</a>
                </div>
              </article>
            )
          }

          )
        }

      </div>

    </section>
  )
}

export default Portfolio